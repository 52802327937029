import React from 'react';
import { getSettingValues } from './utils';
import { Button } from '@components/base/button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import RenderSetting from './RenderSetting';
import { Field, FieldProps } from 'formik';
import { ISetting } from '@biolibtech/biolib-js';
import { FormGroup, Intent } from '@blueprintjs/core';
import ErrorBoundary from '@components/ErrorBoundary';

interface IProps {
    settings: ISetting[];
    fieldProps: FieldProps;
}

export default function RenderGroupArguments(props: IProps) {
    const { form, field } = props.fieldProps;

    const addGroupArgument = () => {
        form.setFieldValue(field.name, [...field.value, getSettingValues(props.settings)]);
    };

    const removeGroupArgument = (index: number) => {
        form.setFieldValue(field.name, field.value.filter((f, i) => i !== index));
    };

    return (
        <>
            {field.value.map((f, index) => (
                <div key={index} className='flex row items-start justify-between'>
                    <div
                        className='my-2 px-3 py-2 bg-gray-50 border border-gray-200 rounded grid grid-cols-1 xl:grid-cols-2 gap-x-6 w-full items-start justify-between'>
                        {props.settings.length === 0 ?
                            <p>No inputs to show</p> :
                            props.settings.map((setting) => {
                                const path = `${field.name}[${index}]${setting.public_id}`;
                                return (
                                    <Field key={path} name={path}>
                                        {(fieldProps: FieldProps) => {
                                            const { touched, error } = fieldProps.meta;
                                            return (
                                                <FormGroup
                                                    className='col-span-1'
                                                    helperText={touched && error && typeof error === 'string' ? error : ''}
                                                    intent={touched && error ? Intent.DANGER : Intent.NONE}
                                                    label={<b>{setting.description}</b>}
                                                >
                                                    <ErrorBoundary fallback={
                                                        <p className='text-red-600'>
                                                            Failed to render input argument
                                                        </p>
                                                    }>
                                                        <RenderSetting setting={setting} fieldProps={fieldProps} />
                                                    </ErrorBoundary>
                                                </FormGroup>
                                            );
                                        }}
                                    </Field>
                                );
                            })}
                    </div>
                    <Button
                        className='h-9 w-9 mt-12 ml-6'
                        color='white'
                        onClick={() => removeGroupArgument(index)}
                    >
                        <span className='text-red-800'><XMarkIcon className='h-4 w-4' /></span>
                    </Button>
                </div>
            ))}
            <Button color='white' className='my-2' onClick={addGroupArgument}>
                Add <PlusIcon className='text-white h-4 w-4' />
            </Button>
        </>
    );
}
